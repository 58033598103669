<template>
  <div>
    <b-row>
      <b-col class="col-12 col-md-4 mb-2">
        <number-with-icon
          :number="widgets.total"
          icon="UsersIcon"
          :title="$t('page.admin_teachers.widgets.total')"
          color="primary"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-4 mb-2">
        <number-with-icon
          :number="widgets.active_sub"
          icon="CheckCircleIcon"
          :title="$t('page.admin_teachers.widgets.active_sub')"
          color="primary"
        />
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-4 mb-2">
        <number-with-icon
          :number="widgets.telegram_number"
          icon="SendIcon"
          title="Всего пользователей авторизовано в боте"
          color="info"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          no-body
          class="mb-0"
        >
          <table-header
            search
            subjects
            :filter="filter"
            :search-updated="getTeachers"
          />
          <b-table
            ref="teachersTable"
            striped
            :items="teachers"
            responsive
            :fields="tableFields"
            primary-key="id"
            show-empty
            :empty-text="$t('page.students.search.labels.no-items')"
            :sort-by.sync="filter.sortBy"
            :sort-desc.sync="filter.sortDesc"
            hover
            @row-clicked="showMore"
          >
            <template #cell(username)="data">
              <div class="d-flex flex-row justify-content-start align-items-center">
                <store-image
                  v-if="data.item.user && data.item.user.image"
                  :image-url="data.item.user.image.full_url"
                  wrapper-class="table-avatar"
                />
                <b-avatar
                  v-else
                  size="25px"
                />
                <span class="d-block ml-1">{{ data.value }}</span>
              </div>
            </template>
            <template #cell(status)="{value}">
              <b-badge
                v-if="value === 'verified'"
                variant="success"
              >
                Верифицирован
              </b-badge>
              <b-badge
                v-else
                variant="warning"
              >
                Не верифицирован
              </b-badge>
            </template>
            <template #cell(actions)="{item}">
              <div class="d-flex justify-content-end">
                <assign-student-modal :teacher="item">
                  <template #activator="{show}">
                    <b-button
                      variant="primary"
                      size="sm"
                      title="Добавить ученика"
                      @click="show"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <feather-icon
                        icon="UserIcon"
                      />
                    </b-button>
                  </template>
                </assign-student-modal>
              </div>
            </template>
          </b-table>
          <table-footer
            :pagination-updated="getTeachers"
            :pagination="pagination"
          />
        </b-card>
      </b-col>
    </b-row>

    <profile-edit-modal
      ref="editModal"
      :user="selectedUser"
    />
  </div>
</template>

<script>
import {
  BCard, BTable, BCol, BRow, BAvatar, BBadge, BButton,
} from 'bootstrap-vue'
import StoreImage from '@/components/store/StoreImage.vue'
import NumberWithIcon from '@/components/widgets/stats/NumberWithIcon.vue'
import TableFooter from '@/components/page-elements/table/TableFooter.vue'
import TableHeader from '@/components/page-elements/table/TableHeader.vue'
import { getUserFullName } from '@/helpers'
import ProfileEditModal from '../../../../components/profile/modals/ProfileEditModal.vue'
import AssignStudentModal from '../../../../components/students/AssignStudentModal.vue'

export default {
  name: 'Students',
  components: {
    AssignStudentModal,
    ProfileEditModal,
    TableHeader,
    TableFooter,
    NumberWithIcon,
    StoreImage,
    BCard,
    BTable,
    BCol,
    BRow,
    BAvatar,
    BBadge,
    BButton,
  },
  data: () => ({
    teachers: [],
    pagination: {
      perPage: 15,
      perPageOptions: [5, 15, 30],
      totalCount: null,
      currentPage: 1,
    },
    widgets: {
      total: 0,
      active_sub: 0,
      telegram_number: 0,
    },
    filter: {
      search: '',
      sortBy: null,
      sortDesc: false,
      subject_ids: null,
    },
    selectedUser: null,
  }),
  computed: {
    sortBy() {
      if (!this.filter.sortBy) return null
      let { sortBy } = this.filter
      if (this.filter.sortDesc) sortBy = `-${sortBy}`
      else sortBy = `+${sortBy}`
      return sortBy
    },
    tableFields() {
      return [
        {
          key: 'username',
          label: this.$t('page.admin_teachers.table.labels.name'),
          sortable: false,
          formatter: (value, key, teacher) => getUserFullName(teacher.user),
        },
        {
          key: 'status',
          label: 'Статус',
          sortable: true,
        },
        {
          key: 'user.phone',
          label: this.$t('page.admin_teachers.table.labels.phone'),
          sortable: false,
        },
        {
          key: 'user.created',
          label: this.$t('page.admin_teachers.table.labels.created_at'),
          sortable: true,
          formatter: (value, key, teacher) => (teacher?.user?.created ? this.$options.filters.dateTime(teacher.user.created) : this.$t('no_data')),
        },
        // {
        //   key: 'subscription',
        //   label: this.$t('page.admin_teachers.table.labels.subscription'),
        //   sortable: false,
        //   formatter: (value, key, teacher) => (teacher.user.subscription ? this.$options.filters.dateTime(teacher.user?.subscription?.end) : this.$t('no_data')),
        // },
        {
          key: 'user.teachersStudentsCountStudents',
          label: this.$t('page.admin_teachers.table.labels.students_number'),
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    sortBy() {
      this.getTeachers()
    },
  },
  async created() {
    await this.getTeachers()
    await this.getWidgetsInfo()
  },
  methods: {
    async getTeachers() {
      const { items, _meta } = await this.$http.get('/users-teachers/index-single-field', {
        params: {
          sort: this.sortBy,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          singleField: this.filter.search,
          subject_ids: this.filter.subject_ids,
          expand: 'user.teachersStudentsCountStudents,user.usersTeachers,user.image',
        },
      })
      this.pagination.totalCount = _meta.totalCount
      this.teachers = items
    },
    async getWidgetsInfo() {
      const { count } = await this.$http.get('/user/count', {
        params: {
          role: 1,
        },
      })
      this.widgets.total = count
      const activeSubInfo = await this.$http.get('/subscription/count', {
        params: {
          is_active: 1,
        },
      })
      this.widgets.active_sub = activeSubInfo.count
      const expiredSubInfo = await this.$http.get('/user/count-telegram')
      this.widgets.telegram_number = expiredSubInfo.count
    },
    showMore(record) {
      this.selectedUser = record.user
      this.$refs.editModal.show()
    },
  },
}
</script>

<style scoped>

</style>
